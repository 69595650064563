/**
 *
 *
 *
 */
import React from 'react';
import { produce } from 'immer';
import * as u from '../utils';
import * as F from './frgs';


/**
 *
 *
 *
 */
export const init = {
  sample: null,
  active: null
};


/**
 *
 *
 *
 */
export const reducer = produce((state = init, action) => {
  switch (action.type) {
    case 'APP_SELECT_SAMPLE': {
      state.sample = action.payload;
      return state;
    }
    case 'APP_CREATE_SAMPLE_MEDIA': {
      if (!state.sample.media) state.sample.media = [];
      state.sample.media.push(action.payload);
      return state;
    }
    case 'APP_REMOVE_SAMPLE_MEDIA': {
      state.sample.media = state.sample.media.filter(e => e.id !== action.payload.id);
      return state;
    }
    case 'APP_SET_ACTIVE': {
      state.active = action.payload;
      return state;
    }
    default: {
      return state;
    }
  }
});

/**
 *
 *
 *
 */
export const getActions = (state, dispatch) => {

  /**
   *
   *
   *
   */
  const inner = {};

  /**
   *
   *
   *
   */
  inner.onCdnGeneratePresignUrl = async function onCdnGeneratePresignUrl(params) {
    const [data, err] = await u.fetcher(F.CDN_GENERATE_PRESIGN_URL, params);
    if (err) return console.log(`onCdnGeneratePresignUrl:err`, err);
    return data;
  };

  /**
   *
   *
   *
   */
  inner.onAppSelectSample = async function onAppSelectSample(token) {
    const [data, err] = await u.fetcher(F.APP_SELECT_SAMPLE, { token });
    if (err) return console.log(`onAppSelectSample:err`, err);
    dispatch({ type: 'APP_SELECT_SAMPLE', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppCreateSampleMedia = async function onAppCreateSampleMedia(params) {
    const [data, err] = await u.fetcher(F.APP_CREATE_SAMPLE_MEDIA, params);
    if (err) return console.log(`onAppCreateSampleMedia:err`, err);
    dispatch({ type: 'APP_CREATE_SAMPLE_MEDIA', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppRemoveSampleMedia = async function onAppRemoveSampleMedia(id) {
    const [data, err] = await u.fetcher(F.APP_REMOVE_SAMPLE_MEDIA, { id });
    if (err) return console.log(`onAppRemoveSampleMedia:err`, err);
    dispatch({ type: 'APP_REMOVE_SAMPLE_MEDIA', payload: data });
  };

  /**
   *
   *
   *
   */
  inner.onAppSetActive = async function onAppSetActive(id) {
    dispatch({ type: 'APP_SET_ACTIVE', payload: id });
  };

  return inner;
};


/**
 *
 *
 *
 */
export const Context = React.createContext({});


/**
 *
 *
 *
 */
export const Provider = (props) => {

  const currReducer = props.reducer ?? reducer;
  const currState = props.state ?? init;
  const [state, dispatch] = React.useReducer(currReducer, currState);
  const actions = getActions(state, dispatch);

  return (
    <Context.Provider value={{ state, dispatch, actions }}>
      {props.children}
    </Context.Provider>
  );
};
