/**
 *
 *
 *
 */
import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import ListaItem from './Lista.Item';
import * as I from '../../_components/Icons';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
export default function Lista() {

  const currFileRef = React.useRef(null);
  const { state, actions } = React.useContext(Store.Context);
  const [ isWait, setIsWait ] = React.useState(false);

  return (
    <S.Container>
      <S.CreateContainer onClick={() => currFileRef?.current?.click()}>
        <I.Plus style={{ transform: 'scale(1.2)' }} />
        <S.FileHide {...onFileProps()} />
      </S.CreateContainer>
      <S.ListaContainer>
        {state.sample?.media?.map(onGetItem)}
      </S.ListaContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onFileProps() {
    return {
      onChange: onFileChange,
      ref: currFileRef,
      type: 'file',
      multiple: false
    };
  }

  /**
   *
   *
   *
   */
  async function onFileChange(evt) {
    setIsWait(true);
    evt.preventDefault();
    evt.stopPropagation();
    const currFile = evt.target.files[0];
    const { type, size } = currFile;
    const name = u.genStr();
    const ext = currFile.type.split('/').pop();
    const params = { bucket: 'assets', name, ext, type, size };
    const data = await actions.onCdnGeneratePresignUrl(params);
    if (!data) return setIsWait(false);
    const headers = { 'Content-Type': currFile.type };
    const config = { headers, onUploadProgress };
    await axios.put(data.url, currFile, config);
    const nex = `https://cdn.parvion.uk/assets/${name}.${ext}`;
    const args = { sample_id: state.sample.id, url: nex, type };
    await actions.onAppCreateSampleMedia(args);
    return setIsWait(false);
  }

  /**
   *
   *
   *
   */
  function onUploadProgress(evt) {
    const { loaded, total } = evt;
    const prct = Math.round((loaded * 100) / total);
    console.log(`onUploadProgress:prct`, prct);
    // return setProgress(prct);
  }

  /**
   *
   *
   *
   */
  function onGetItem(e) {

    return (
      <ListaItem key={e.id} {...e} />
    );
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

S.CreateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 72px;
  border-radius: 6px;
  background: rgba(134, 46, 156, 0.1);
  svg { stroke: rgb(134, 46, 156); }
  &:hover { cursor: pointer; background: rgba(134, 46, 156, 0.2); }
`;

S.FileHide = styled.input`
  visibility: hidden;
  position: absolute;
  outline: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

S.ListaContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  overflow-x: scroll;
`;