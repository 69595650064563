/**
 *
 *
 *
 */
export const APP_SELECT_SAMPLE = `
  mutation AppSelectSample($token: String!) {
    app_select_sample(token: $token)
  }
`;


/**
 *
 *
 *
 */
export const CDN_GENERATE_PRESIGN_URL = `
  mutation CdnGeneratePresignUrl(
    $bucket: String!,
    $name: String!,
    $ext: String!,
    $type: String!,
    $size: Int!
  ) {
    cdn_generate_presign_url(
      bucket: $bucket,
      name: $name,
      ext: $ext,
      type: $type,
      size: $size
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_CREATE_SAMPLE_MEDIA = `
  mutation AppCreateSampleMedia(
    $sample_id: ID!,
    $url: String!,
    $type: String!
  ) {
    app_create_sample_media(
      sample_id: $sample_id,
      url: $url,
      type: $type
    )
  }
`;


/**
 *
 *
 *
 */
export const APP_REMOVE_SAMPLE_MEDIA = `
  mutation AppRemoveSampleMedia($id: ID!) {
    app_remove_sample_media(id: $id)
  }
`;
