/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as I from '../../_components/Icons';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function ListaItem(props) {

  const { state, actions } = React.useContext(Store.Context);
  const $isCurrActive = Number(props.id) === Number(state.active);
  const onItemClick = () => actions.onAppSetActive(props.id);
  const subProps = { $isCurrActive, onClick: onItemClick };

  return (
    <S.Container {...subProps}>
      {$isCurrActive && (
        <S.IconContainer>
          <I.Bin style={{ transform: 'scale(1.5)' }} />
      </S.IconContainer>
      )}
      <S.Image src={props.url} />
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  position: relative;
  width: 72px;
  height: 72px;
  border-radius: 6px;
  flex-shrink: 0;
  background: rgba(134, 46, 156, 0.1);
  border: 1px solid rgb(237, 239, 244);
  &:hover { cursor: pointer; opacity: 0.8; }
  ${p => p.$isCurrActive && `border: 1px solid #862e9c;`}
`;

S.Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 6px;
  object-fit: cover;
`;

S.IconContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.5);
  svg {
    stroke: #fff;
    transform: scale(1.5)
  }
`;
