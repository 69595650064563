/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import T from '../../_components/Typography';
import Lista from '../components/Lista';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function Token() {

  const { token } = Router.useParams();
  const { state, actions } = React.useContext(Store.Context);
  React.useEffect(() => { onToken(); }, []);
  const onSame = elm => Number(elm.id) === Number(state.active);
  const media = state.sample?.media?.find(onSame);

  return (
    <S.Container>
      <Lista />
      <S.ImageContainer>
        <S.Image src={media?.url} />
      </S.ImageContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onToken() {
    await actions.onAppSelectSample(token);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  padding-top: 10px;
  height: 100%;
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;


S.ImageContainer = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  background-color: rgb(246, 248, 250);
  border: 1px solid rgb(237, 239, 244);
  border-radius: 6px;
`;

S.Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
